<template>
  <div>
    <c-table
      ref="equipTable"
      title="설비 목록"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :gridHeight="grid.height"
      rowKey="equipmentCd"
      @linkClick="linkClick"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equip-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          equipmentTypeCd: '',
          grade: '',
          managementDepts: '',
          inspectionCycleCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'equipmentCd',
            field: 'equipmentCd',
            label: '설비코드',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            sortable: true,
            type: 'link',
          },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'inspectionCycleName',
            field: 'inspectionCycleName',
            label: '법정점검주기',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'managementDeptName',
            field: 'managementDeptName',
            label: '관리부서',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
        ],
        data: [],
        height: '600px'
      },
      searchParam: {
        plantCd: null,
        equipmentTypeCd: '',
        equipmentName: '',
        inspectionCycleCd: '',
        equipInspectionTypeCd: null,
        managementDepts: '',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 450;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.equipment.list.url;
      if (this.popupParam.equipmentTypeCd) {
        this.searchParam.equipmentTypeCd = this.popupParam.equipmentTypeCd
        this.searchParam.grade = this.popupParam.grade;
        this.searchParam.inspectionCycleCd = this.popupParam.inspectionCycleCd;
        this.searchParam.managementDepts = this.popupParam.managementDepts;
      }
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
        psmFlag: row ? row.psmFlag : '',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/mdm/fim/equipmentDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
